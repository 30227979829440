<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/about/bg.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">关于宁讯</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="Profile">
          公司简介：
          <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宁讯信息科技有限公司成立于2012年12月，核心团队由国内资深行业高等级软硬件研发与测试人员和技术营销型复合销售人员构成。
          <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司主要产品定位物联网传输层无线传输产品为主，包括工业物联网安全通讯网关、智能终端相关增值解决方案。
          <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;专注致力于工业级无线网络智能产品及解决方案的提供商，公司主要产品有工业无线传输系列、无线智能保管柜系列以及智能维护平台中间件系列，其中无线产品包括多功能无线控制器、无线移动切换基站、无线智能终端、一线通智能无线覆盖基站及RFID读写单元。公司产品市场主要定位应用在数字石化油田、数字工厂、智能电力、巡检机器人、AGV机器人、无人驾驶、新能源等领域。
          </div>
        <div class="brand">
          <img src="../../assets/common/pic/logoRB.png" alt="">
          <div>品牌诠释：</div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中文 宁讯，LINK英文意为连接，”KOM” 德语为“COM”通讯、通信同义表示，LINKOM，即为连接通信；
          <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;品牌定义产品要做工业等级高品质的通信相关产品及系统平台相关解决方案。
        </div>
        <div class="patent">
          拥有专利软著：<br>
          <img src="../../assets/about/logo.png" alt="">
          <img src="../../assets/about/certification.jpg" alt="">
          <img src="../../assets/about/soft.png" alt="">
          <img src="../../assets/about/patent.png" alt="">
          <img src="../../assets/about/approve.png" alt="">
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'About',
  components: {
    topBarY,
    baseBar
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -500px;
  width: 1920px;
}

.switchBar {
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

/* 关于宁讯 */
.switchContiner .contactContiner {
  margin: 0 auto;
  width: 1400px;
  min-height: 900px;
  border-radius: 5px;
  background-color: #fff;
}
.switchContiner .contactContiner .Profile {
  padding: 40px;
  font-size: 14px;
  line-height: 34px;
}
.switchContiner .contactContiner .brand {
  font-size: 14px;
  padding: 40px;
  line-height: 34px;
}
.switchContiner .contactContiner .brand img {
  width: 150px;
}
.switchContiner .contactContiner .patent {
  padding: 40px;
  font-size: 14px;
}
.switchContiner .contactContiner .patent img {
  width: 200px;
  margin-top: 26px;
  margin-right: 26px;
}
</style>
